import { createReduxConstants } from '@bibliocommons/utils-redux';

export const MATERIAL_TYPE_PHYSICAL = 'PHYSICAL';
export const MATERIAL_TYPE_DIGITAL = 'DIGITAL';

export const FORMAT_CHOOSER_MODAL = 'format-chooser-modal';
export const OTHER_LANGUAGES_MODAL = 'other-languages-modal';
export const FILTER_SEPARATOR = '|';
export const FILTER_PREFIX = 'f_';
export const PROBES_AVAILABILITY_GROUP = 'PROBES_AVAILABILITY_GROUP';

export const FACET_FIELDS_ORDER = [
  'IS_PRIVATE',
  'I_OWN_THIS',
  'SEARCH_SCOPE',
  'STATUS',
  'FORMAT',
  'NEWLY_ACQUIRED',
  'CIRC',
  'FICTION_TYPE',
  'AUDIENCE',
  'GENRE_HEADINGS',
  'TOPIC_HEADINGS',
  'GEO_HEADINGS',
  'AUTHOR',
  'LANGUAGES',
  'PUBLISHED_DATE',
  'UGC_RATING',
  'TAGS',
  'TECHNICAL_DIFFICULTY'
];

export const FACET_FIELDS_ORDER_ONLINE_FIRST = [
  'IS_PRIVATE',
  'I_OWN_THIS',
  'SEARCH_SCOPE',
  'CIRC',
  'STATUS',
  'FORMAT',
  'NEWLY_ACQUIRED',
  'FICTION_TYPE',
  'AUDIENCE',
  'GENRE_HEADINGS',
  'TOPIC_HEADINGS',
  'GEO_HEADINGS',
  'AUTHOR',
  'LANGUAGES',
  'PUBLISHED_DATE',
  'UGC_RATING',
  'TAGS',
  'TECHNICAL_DIFFICULTY'
];

export const FORMAT_GROUP_ORDER = [
  'BOOKS',
  'AUDIOBOOKS_SPOKEN_WORD',
  'MOVIES_TV_VIDEO',
  'MUSIC_SOUND',
  'GAMES_INTERACTIVE_MEDIA',
  'JOURNALS_MAGAZINES',
  'ACCESSIBLE_FORMATS',
  'OTHER'
];

export const AVAILABILITY_GROUP_ORDER = [
  'AVAILABLE_ITEMS',
  'AVAILABLE_BY_REQUEST_ITEMS',
  PROBES_AVAILABILITY_GROUP,
  'LIBRARY_USE_ONLY_ITEMS',
  'RESTRICTED_ACCESS_ITEMS',
  'ON_ORDER',
  'EXTENDED_AVAILABLE_ITEMS',
  'EXTENDED_AVAILABLE_BY_REQUEST_ITEMS',
  'EXTENDED_LIBRARY_USE_ONLY_ITEMS',
  'EXTENDED_RESTRICTED_ACCESS_ITEMS',
  'NOT_AVAILABLE_ITEMS',
  'EXTENDED_NOT_AVAILABLE_ITEMS',
  'IN_LIBRARY'
];

export const CONTENT_PROVIDERS = {
  NOVELIST: 'https://novselect.ebscohost.com/',
  I_DREAM_BOOKS: 'https://idreambooks.com/',
  CONTENT_CAFE: 'http://contentcafe2.btol.com/',
  SYNDETICS: 'http://syndetics.com/',
  BIBLIOCOMMONS: 'https://bibliocommons.com/',
  LIBRARY_THING: 'https://www.librarything.com/'
};

export default createReduxConstants('BIB', {
  FETCH_CATALOG_BIB_REQUEST: null,
  FETCH_CATALOG_BIB_SUCCESS: null,
  FETCH_CATALOG_BIB_FAILURE: null,
  FETCH_AVAILABILITY_REQUEST: null,
  FETCH_AVAILABILITY_SUCCESS: null,
  FETCH_AVAILABILITY_FAILURE: null,
  FETCH_DIGITAL_FORMATS_REQUEST: null,
  FETCH_DIGITAL_FORMATS_SUCCESS: null,
  FETCH_DIGITAL_FORMATS_FAILURE: null,
  FETCH_USER_TRANSACTIONS_REQUEST: null,
  FETCH_USER_TRANSACTIONS_SUCCESS: null,
  FETCH_USER_TRANSACTIONS_FAILURE: null,
  FETCH_DISCOVERY_REQUEST: null,
  FETCH_DISCOVERY_SUCCESS: null,
  FETCH_DISCOVERY_FAILURE: null,
  FETCH_EVALUATION_REQUEST: null,
  FETCH_EVALUATION_SUCCESS: null,
  FETCH_EVALUATION_FAILURE: null,
  FETCH_UGC_REQUEST: null,
  FETCH_UGC_SUCCESS: null,
  FETCH_UGC_FAILURE: null,
  FETCH_HOLDABLE_ITEMS_REQUEST: null,
  FETCH_HOLDABLE_ITEMS_SUCCESS: null,
  FETCH_HOLDABLE_ITEMS_FAILURE: null,
  GET_MARC_REQUEST: null,
  GET_MARC_SUCCESS: null,
  GET_MARC_FAILURE: null,
  GET_REVIEWS_REQUEST: null,
  GET_REVIEWS_SUCCESS: null,
  GET_REVIEWS_FAILURE: null,
  GET_RECOMMENDATIONS_REQUEST: null,
  GET_RECOMMENDATIONS_SUCCESS: null,
  GET_RECOMMENDATIONS_FAILURE: null
});
