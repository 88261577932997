import Immutable from 'immutable';
import { combineReducers } from 'redux-immutablejs';

import eventsSearch from './EventsSearchReducer';
import myEvents from './MyEventsReducer';

export default combineReducers(
  Immutable.Map({
    eventsSearch,
    myEvents
  })
);
