import { createReduxConstants } from '@bibliocommons/utils-redux';

export const CHECKOUT_STATUS_TYPES = {
  OUT: 'OUT',
  COMING_DUE: 'COMING_DUE',
  OVERDUE: 'OVERDUE'
};

export default createReduxConstants('CHECKOUT', {
  DIGITAL_CHECKOUT_REQUEST: null,
  DIGITAL_CHECKOUT_SUCCESS: null,
  DIGITAL_CHECKOUT_FAILURE: null,
  DIGITAL_FORMATS_REQUEST: null,
  DIGITAL_FORMATS_SUCCESS: null,
  DIGITAL_FORMATS_FAILURE: null,
  DIGITAL_CHECK_IN_REQUEST: null,
  DIGITAL_CHECK_IN_SUCCESS: null,
  DIGITAL_CHECK_IN_FAILURE: null,
  DIGITAL_FORMAT_LOCK_REQUEST: null,
  DIGITAL_FORMAT_LOCK_SUCCESS: null,
  DIGITAL_FORMAT_LOCK_FAILURE: null,
  FETCH_CHECKOUTS_REQUEST: null,
  FETCH_CHECKOUTS_SUCCESS: null,
  FETCH_CHECKOUTS_FAILURE: null,
  RENEW_REQUEST: null,
  RENEW_SUCCESS: null,
  RENEW_FAILURE: null,
  QUICK_RENEW_REQUEST: null,
  QUICK_RENEW_SUCCESS: null,
  QUICK_RENEW_FAILURE: null,
  GET_FULFILMENT_REQUEST: null,
  GET_FULFILMENT_SUCCESS: null,
  GET_FULFILMENT_FAILURE: null
});
