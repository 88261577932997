import { createReduxConstants } from '@bibliocommons/utils-redux';

export default createReduxConstants('AVAILABILITY', {
  OPEN_AVAILABILITY_DETAILS: null,
  CLOSE_AVAILABILITY_DETAILS: null,

  DIGITAL_AVAILABILITY_REQUEST: null,
  DIGITAL_AVAILABILITY_SUCCESS: null,
  DIGITAL_AVAILABILITY_ERROR: null
});
