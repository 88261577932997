import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { bindAll } from '@bibliocommons/utils-react';
import { catalogBibShape } from '@bibliocommons/bc-prop-types';
import { selectCatalogBibEntities } from 'app/selectors/EntitiesSelector';
import { trackContentClick } from 'app/actions/AnalyticsGa4Actions';
import BibBrief from './BibBrief';

export class BibBriefContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  handleBibJacketClick() {
    const { source, catalogBib, sourceComponent } = this.props;

    const metadata = {
      metadata_category_type: 'bibliographic_details_title',
      metadata_value: catalogBib.getIn(['brief', 'title']) ?? undefined,
      metadata_enrichment_provider: source?.toLowerCase()
    };
    const ui = {
      ui_container_title:
        sourceComponent === 'AllRecommendationsPage' ? 'recommendation-you-may-also-like-all' : 'source-content',
      ui_container_type: 'component',
      ui_component_type: 'link-image'
    };

    this.props.trackContentClick({ bibId: catalogBib.get('id'), metadata, ui });
  }

  handleBibTitleClick() {
    const { source, catalogBib, sourceComponent } = this.props;

    const metadata = {
      metadata_category_type: 'bibliographic_details_title',
      metadata_value: catalogBib.getIn(['brief', 'title']) ?? undefined,
      metadata_enrichment_provider: source?.toLowerCase()
    };
    const ui = {
      ui_container_title:
        sourceComponent === 'AllRecommendationsPage' ? 'recommendation-you-may-also-like-all' : 'source-content',
      ui_container_type: 'component',
      ui_component_type: 'link-text',
      ui_component_label: catalogBib.getIn(['brief', 'title']) ?? undefined
    };

    this.props.trackContentClick({ bibId: catalogBib.get('id'), metadata, ui });
  }

  render() {
    return (
      <BibBrief
        {...this.props}
        handleBibJacketClick={this.handleBibJacketClick}
        handleBibTitleClick={this.handleBibTitleClick}
      />
    );
  }
}

BibBriefContainer.propTypes = {
  // ownProps
  metadataId: PropTypes.string.isRequired,
  // Redux props
  catalogBib: catalogBibShape.isRequired,
  trackContentClick: PropTypes.func,
  source: PropTypes.string,
  sourceComponent: PropTypes.string
};

const mapStateToProps = (state, ownProps) => ({
  catalogBib: selectCatalogBibEntities(state).get(ownProps.metadataId)
});

const mapDispatchToProps = dispatch => ({
  trackContentClick: bindActionCreators(trackContentClick, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(BibBriefContainer);
