import { createReduxConstants } from '@bibliocommons/utils-redux';

export default createReduxConstants('RELATIONSHIPS', {
  CREATE_FOLLOW_FAILURE: null,
  CREATE_FOLLOW_REQUEST: null,
  CREATE_FOLLOW_SUCCESS: null,
  CREATE_IGNORE_FAILURE: null,
  CREATE_IGNORE_REQUEST: null,
  CREATE_IGNORE_SUCCESS: null,
  FETCH_RELATIONSHIPS_FAILURE: null,
  FETCH_RELATIONSHIPS_REQUEST: null,
  FETCH_RELATIONSHIPS_SUCCESS: null,
  REMOVE_FOLLOW_FAILURE: null,
  REMOVE_FOLLOW_REQUEST: null,
  REMOVE_FOLLOW_SUCCESS: null,
  REMOVE_IGNORE_FAILURE: null,
  REMOVE_IGNORE_REQUEST: null,
  REMOVE_IGNORE_SUCCESS: null
});
