import loadable from '@loadable/component';

export const IntlWrapper = loadable(() => import('./IntlWrapper'));
export const FeedItemDropdown = loadable(() => import('app/components/shared/FeedItemDropdown'));
export const UserPrivacySettingsToggle = loadable(() => import('@bibliocommons/user-privacy-settings-toggle'));

// Carousels
// TODO: (https://bibliocommons.atlassian.net/browse/CORE-70416) Remove BaseCarousel and  RatingCard once Core V1 has been updated to use RecentlyRatedCarousel
export const BaseCarousel = loadable(() => import('@bibliocommons/carousel-base'), {
  resolveComponent: imported => imported.default.BaseCarousel
});
// TODO: (https://bibliocommons.atlassian.net/browse/CORE-70416) See if this can be removed.
export const LocalizedCatalogueCarousel = loadable(() => import('@bibliocommons/catalogue-carousel'), {
  resolveComponent: imported => imported.LocalizedCatalogueCarousel
});
// TODO: (https://bibliocommons.atlassian.net/browse/CORE-70416) See if this can be removed.
export const HtmlCarousel = loadable(() => import('@bibliocommons/html-carousel'));
export const ListCarousel = loadable(() => import('app/sdk/components/ListCarousel'));
export const CatalogueCarousel = loadable(() => import('app/sdk/components/CatalogueCarousel'));
export const RatingCard = loadable(() => import('@bibliocommons/rating-card'));
export const RecentlyRatedCarousel = loadable(() => import('app/sdk/components/RecentlyRatedCarousel'));

// Lists
export const UserListVisibilityLabel = loadable(() => import('app/components/lists'), {
  resolveComponent: imported => imported.VisibilityLabel
});
export const UserListStatusBanner = loadable(() => import('app/components/lists'), {
  resolveComponent: imported => imported.StatusBanner
});
export const UserListForm = loadable(() => import('app/components/lists'), {
  resolveComponent: imported => imported.ListForm
});
export const UserListJacketCover = loadable(() => import('app/components/lists'), {
  resolveComponent: imported => imported.ListJacketCover
});
