import { createReduxConstants } from '@bibliocommons/utils-redux';

export default createReduxConstants('AGE_SUITABILITIES', {
  ADD_AGE_SUITABILITIES_REQUEST: null,
  ADD_AGE_SUITABILITIES_SUCCESS: null,
  ADD_AGE_SUITABILITIES_FAILURE: null,
  UPDATE_AGE_SUITABILITIES_REQUEST: null,
  UPDATE_AGE_SUITABILITIES_SUCCESS: null,
  UPDATE_AGE_SUITABILITIES_FAILURE: null,
  REMOVE_AGE_SUITABILITIES_REQUEST: null,
  REMOVE_AGE_SUITABILITIES_SUCCESS: null,
  REMOVE_AGE_SUITABILITIES_FAILURE: null
});
