import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { jacketShape } from '@bibliocommons/bc-prop-types';
import { bindAll } from '@bibliocommons/utils-react';
import { sendAnalyticsEvents } from '@bibliocommons/utils-analytics';
import { bibEventComposer } from '@bibliocommons/analytics-event-composers';
import AnalyticsConstants from '@bibliocommons/constants-analytics';
import { selectCatalogBib } from 'app/selectors/catalogBibs/CatalogBibSelector';
import BibJacket from './BibJacket';

const { composeImageClickEvent } = bibEventComposer;
const { CUSTOM_DIMENSIONS } = AnalyticsConstants;

export class BibJacketContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    bindAll(this);
  }

  handleClick() {
    const { metadataId, onClick, reciprocalRank } = this.props;

    sendAnalyticsEvents(
      composeImageClickEvent({
        containerName: CUSTOM_DIMENSIONS.CONTAINER_NAMES.BIB_ITEM,
        reciprocalRank,
        metadataId
      })
    );

    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  }

  render() {
    return <BibJacket {...this.props} handleClick={this.handleClick} />;
  }
}

BibJacketContainer.propTypes = {
  onClick: PropTypes.func,
  renderAsLink: PropTypes.bool,
  reciprocalRank: PropTypes.number,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  format: PropTypes.string,
  cover: jacketShape,
  metadataId: PropTypes.string.isRequired,
  source: PropTypes.string
};

const mapStateToProps = (state, ownProps) => ({
  catalogBib: ownProps.catalogBib || selectCatalogBib(state)
});

export default connect(mapStateToProps)(BibJacketContainer);
