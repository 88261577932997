import '@bibliocommons/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import httpClient from 'superagent';
import Immutable from 'immutable';
import { MemoryRouter } from 'react-router-dom';

import { convertLegacyIdToMetadataId } from '@bibliocommons/utils-link';

import {
  setSdkConfig,
  trackContentClick,
  trackNavigate,
  trackPagePrint,
  trackPageView,
  trackSdkCheckoutConsumeAccess,
  trackSdkCheckoutPlace,
  trackSdkHoldingsConsumeAccess,
  trackSdkHoldingsView,
  trackSdkHoldPlace,
  trackSdkLogin,
  trackSdkUserAccountRegister,
  trackSdkUserContentAdd,
  trackSdkUserContentModify,
  trackSdkUserEngageFollow
} from 'app/actions/AnalyticsGa4Actions';
import createReduxStore from 'app/helpers/redux/createReduxStore';
import ErrorService from 'app/services/ErrorService';
import ApiClient from 'app/api/ApiClient';
import ApplicationContainer from '../ApplicationContainer';
import ComponentWrapper from './ComponentWrapper';
import * as legacyComponents from './legacyComponents';
import * as components from './components';

const { BiblioCommonsSDK } = window;

/*
 * Exports
 */
BiblioCommonsSDK.React = React;
BiblioCommonsSDK.ReactDOM = ReactDOM;
BiblioCommonsSDK.PropTypes = PropTypes;
BiblioCommonsSDK.Immutable = Immutable;
BiblioCommonsSDK.legacyComponents = legacyComponents;
BiblioCommonsSDK.components = components;

// Initialize
const initialState = BiblioCommonsSDK.__initialState__;
delete BiblioCommonsSDK.__initialState__;

const { apiGatewayURL, libraryDomain, airbrakeConfig, enableWebpackHMR, enableReduxDevTools } = initialState.app;

ErrorService.initialize(airbrakeConfig);

const currentLanguage = initialState.localization.currentLanguage;
const apiClient = new ApiClient(httpClient, {
  locale: currentLanguage,
  subdomain: libraryDomain,
  apiGatewayURL
});

const reduxStore = createReduxStore(Immutable.fromJS(initialState), apiClient, enableWebpackHMR, enableReduxDevTools);

const analyticsGa4 = {
  setConfig(config) {
    reduxStore.dispatch(setSdkConfig(config));
  },
  trackCheckoutConsumeAccess(bib) {
    if (!bib) {
      throw new Error('a bib entity is required to track a checkout consume access event');
    }

    reduxStore.dispatch(trackSdkCheckoutConsumeAccess(bib));
  },
  trackCheckoutPlace(bib) {
    if (!bib) {
      throw new Error('a bib entity is required to track a checkout place');
    }

    reduxStore.dispatch(trackSdkCheckoutPlace(bib));
  },
  trackContentClick({ bib, list, metadata, ui }) {
    reduxStore.dispatch(
      trackContentClick({
        bib,
        list,
        metadata,
        ui
      })
    );
  },
  trackHoldingsConsumeAccess(bib, metadata, ui) {
    if (!(bib && metadata && ui)) {
      throw new Error('bib, metadata, and ui entities are required to track a holdings consume access event');
    }

    reduxStore.dispatch(
      trackSdkHoldingsConsumeAccess({
        bib,
        metadata,
        ui
      })
    );
  },
  trackHoldingsView(bib) {
    if (!bib) {
      throw new Error('a bib entity is required to track a holdings view');
    }

    reduxStore.dispatch(trackSdkHoldingsView(bib));
  },
  trackHoldPlace(bib) {
    if (!bib) {
      throw new Error('a bib entity is required to track a hold place');
    }

    reduxStore.dispatch(trackSdkHoldPlace(bib));
  },
  trackLogIn(user) {
    if (!(user || BiblioCommonsSDK.getCurrentUser())) {
      throw new Error('a current user must exist in order to track a log in');
    }

    reduxStore.dispatch(trackSdkLogin(user));
  },
  trackNavigate({ metadata, ui }) {
    reduxStore.dispatch(trackNavigate({ metadata, ui }));
  },
  trackPagePrint() {
    reduxStore.dispatch(trackPagePrint());
  },
  trackPageView({ list }) {
    reduxStore.dispatch(
      trackPageView({
        bibId: null,
        list
      })
    );
  },
  trackUserAccountRegister(user) {
    if (!(user || BiblioCommonsSDK.getCurrentUser())) {
      throw new Error('a current user must exist in order to track a user account registration');
    }

    reduxStore.dispatch(trackSdkUserAccountRegister(user));
  },
  trackUserContentAdd({ list } = { list: undefined }) {
    reduxStore.dispatch(
      trackSdkUserContentAdd({
        list
      })
    );
  },
  trackUserContentModify({ list } = { list: undefined }) {
    reduxStore.dispatch(
      trackSdkUserContentModify({
        list
      })
    );
  },
  trackUserEngageFollow() {
    reduxStore.dispatch(trackSdkUserEngageFollow());
  }
};

BiblioCommonsSDK.utils = {
  convertLegacyIdToMetadataId
};

/* PUBLIC FUNCTIONS */

/*
 * Executes the given callback when the sdk is fully loaded.
 * @param {Function} callback
 */
BiblioCommonsSDK.init = function init(callback) {
  callback();
};

/*
 * Provides a callback with access to the analyticsGa4 object
 * for pushing analytics events to the global data layer.
 * @param {function} callback
 */
BiblioCommonsSDK.onAnalyticsReady = function onAnalyticsReady(callback) {
  callback(analyticsGa4);
};

/*
 * Renders the given component with the given name if supported.
 * @param {string}  componentName
 * @param {string|Element}  container
 * @param {object}  props
 * @param {function} callback
 */
BiblioCommonsSDK.renderComponent = async function renderComponent(componentName, container, props = {}, callback) {
  const Component = components[componentName] || legacyComponents[componentName];
  const target = typeof container === 'string' ? document.querySelector(container) : container;
  const isLegacyComponent = !!legacyComponents[componentName];

  if (!Component) {
    throw new Error(`Component ${componentName} was not found`);
  }

  if (!target) {
    throw new Error('Target container does not exist');
  }

  if (Component.load) {
    await Component.load();
  }

  ReactDOM.render(
    <Provider store={reduxStore}>
      <ApplicationContainer messages={props.messages}>
        <MemoryRouter>
          <ComponentWrapper legacy={isLegacyComponent}>
            <Component {...props} />
          </ComponentWrapper>
        </MemoryRouter>
      </ApplicationContainer>
    </Provider>,
    target,
    callback
  );
};

BiblioCommonsSDK.renderWidget = BiblioCommonsSDK.renderComponent; // Backwards compatibility

// Flush the callQueue
BiblioCommonsSDK.__callQueue__.forEach(([method, args]) => BiblioCommonsSDK[method](...args));
delete BiblioCommonsSDK.__callQueue__;

export default BiblioCommonsSDK;
