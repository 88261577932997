import Immutable from 'immutable';
import { createSelector } from 'reselect';

import { selectAppConfig } from 'app/selectors/AppSelector';
import {
  selectBibEntities,
  selectListEntities,
  selectSeriesEntryEntities,
  selectCardEntities
} from 'app/selectors/EntitiesSelector';
import { selectCurrentUser } from 'app/selectors/AuthSelector';

const selectConfigOverrides = state => state.get('analyticsGa4');

const selectBibEntity = createSelector(
  [selectBibEntities, selectSeriesEntryEntities, (_state, bibId) => bibId],
  (bibs, seriesBibs, bibId) => {
    if (!bibId) {
      return undefined;
    }

    const bib = bibs.get(bibId) ?? seriesBibs?.get(bibId);
    if (!bib) {
      return undefined;
    }

    return Immutable.Map({
      bib_audience: bib.getIn(['briefInfo', 'audiences', 0]) ?? undefined,
      bib_availability_status: bib.getIn(['availability', 'localisedStatus']) ?? undefined,
      bib_fiction_type: bib.getIn(['briefInfo', 'contentType']) ?? undefined,
      bib_format: bib.getIn(['briefInfo', 'format']) ?? undefined,
      bib_fulfillment_provider: bib.getIn(['policy', 'provider']) ?? undefined,
      bib_group_key: bib.getIn(['briefInfo', 'groupKey']) ?? undefined,
      bib_hold_count: bib.getIn(['availability', 'heldCopies']) ?? undefined,
      bib_metadata_id: bib.get('id') ?? undefined,
      bib_total_item_count: bib.getIn(['availability', 'totalCopies']) ?? undefined
    });
  }
);

const selectListEntity = createSelector([selectListEntities, (_state, listId) => listId], (lists, listId) => {
  if (!listId) {
    return undefined;
  }

  const list = lists.get(listId.toString());
  if (!list) {
    return undefined;
  }

  return Immutable.Map({
    list_id: list.get('id'),
    list_purpose: list.get('listType'),
    list_title: list.get('name'),
    list_visibility: `${list.get('agencyScope')}_${list.get('intendedLocale')}`
  });
});

const selectCardEntity = createSelector([selectCardEntities, (_state, cardId) => cardId], (cards, cardId) => {
  if (!cardId) {
    return undefined;
  }

  const card = cards.get(cardId.toString());
  if (!card) {
    return undefined;
  }

  return Immutable.Map({
    card_id: card.get('id'),
    card_title: card.getIn(['title', 'text']),
    card_type: card.getIn(['contentType', 'slug']).replace('-', '_')
  });
});

const selectOrganizationEntity = createSelector([selectAppConfig], appConfig => {
  const subdomain = appConfig?.get('libraryDomain') ?? undefined;
  return Immutable.Map({ subdomain });
});

const selectProductEntity = createSelector([selectAppConfig, selectConfigOverrides], (appConfig, overrides) => {
  const currentURL = appConfig?.get('currentURL');
  const isMobile = appConfig?.get('mobileApp', false) ?? false;
  const overrideProductId = overrides?.get('productId') ?? null;
  const overrideProductVersion = overrides?.get('productVersion') ?? null;

  let productId = overrideProductId;
  if (overrideProductId) {
    productId = overrideProductId;
  } else if (isMobile) {
    productId = `apps_${appConfig?.get('mobileAppPlatform') ?? ''}`.toLowerCase();
  } else if (currentURL?.includes('/v2/events')) {
    productId = 'events';
  } else if (currentURL?.includes('/v2/locations')) {
    productId = 'web';
  } else {
    productId = 'core';
  }

  let productVersion;
  if (overrideProductVersion) {
    productVersion = overrideProductVersion;
  } else if (isMobile) {
    productVersion = appConfig?.get('mobileAppVersion');
  } else {
    productVersion = appConfig?.get('coreVersion');
  }

  return Immutable.Map({
    product_id: productId ?? undefined,
    product_version: productVersion ?? undefined
  });
});

const selectUserEntity = createSelector([selectCurrentUser], currentUser => {
  if (!currentUser) {
    return undefined;
  }

  const analyticsId = currentUser.get('encodedId');
  const isStaff = currentUser.get('staff');

  let userType;
  if (isStaff === true) {
    userType = 'staff';
  } else if (isStaff === false) {
    userType = 'patron';
  }

  return Immutable.Map({
    user_analytics_id: analyticsId ?? undefined,
    user_type: userType ?? undefined
  });
});

export {
  selectBibEntity,
  selectListEntity,
  selectCardEntity,
  selectOrganizationEntity,
  selectProductEntity,
  selectUserEntity
};
