import { createReduxConstants } from '@bibliocommons/utils-redux';

export default createReduxConstants('CONTENT_ADVISORIES', {
  ADD_CONTENT_ADVISORIES_REQUEST: null,
  ADD_CONTENT_ADVISORIES_SUCCESS: null,
  ADD_CONTENT_ADVISORIES_FAILURE: null,
  UPDATE_CONTENT_ADVISORIES_REQUEST: null,
  UPDATE_CONTENT_ADVISORIES_SUCCESS: null,
  UPDATE_CONTENT_ADVISORIES_FAILURE: null,
  REMOVE_CONTENT_ADVISORIES_REQUEST: null,
  REMOVE_CONTENT_ADVISORIES_SUCCESS: null,
  REMOVE_CONTENT_ADVISORIES_FAILURE: null,
  GET_CONTENT_ADVISORY_REQUEST: null,
  GET_CONTENT_ADVISORY_SUCCESS: null,
  GET_CONTENT_ADVISORY_FAILURE: null,
  GET_CONTENT_ADVISORIES_REQUEST: null,
  GET_CONTENT_ADVISORIES_SUCCESS: null,
  GET_CONTENT_ADVISORIES_FAILURE: null
});
